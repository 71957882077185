<template>
  <div class="mb_download_ios_check" :class="pc ? '' : 'isPhone'">
    <div class="ios_check">
      <img src="../assets/image/mb_img_1_3.png" alt="" />
      <p class="ios_check_head">身份验证</p>
      <div class="form_item">
        <div class="input_item">
          <el-input placeholder="请输入手机号码" v-model="phone">
            <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
          </el-input>
          <el-button type="primary" @click="getSms()" v-if="smsTime === 0"
            >获取验证码</el-button
          >
          <el-button class="reacquire" type="primary" :disabled="true" v-else
            >重新获取({{ this.smsTime }}s)</el-button
          >
        </div>
        <div class="full_input">
          <el-input placeholder="请输入验证码" v-model="code">
            <i slot="prefix" class="el-input__icon el-icon-paperclip"></i>
          </el-input>
        </div>
        <div class="full_btn">
          <el-button
            :disabled="!this.phone || !this.code"
            type="primary"
            @click="checkUser()"
            >验证身份</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mb_download_ios_check",
  props: {},
  data() {
    return {
      phone: "",
      code: "",
      pc: true,
      hiedTimer: null,
      smsTime: 0,
      smsTImer: null,
    };
  },
  mounted() {
    this.pc = this.IsPC();
    this.hiedTimer = setInterval(() => {
      if (document.querySelector("#ebgnav-box")) {
        this.hideHead();
        clearInterval(this.hiedTimer);
      }
    }, 200);
  },
  methods: {
    hideHead() {
      if (!this.pc) {
        document.querySelector("#ebgnav-box").style.display = "none";
      }
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    getSms() {
      let isPhone = this.isMobile(this.phone);
      if (!isPhone) {
        this.$message.warning("请输入正确手机号");
        return false;
      }
      this.$api
        .getSmsCodeIosDownload(this.phone)
        .then((res) => {
          console.log(res);
          if (res.status == 1) {
            this.$message.success("验证码已发送！");
            this.setSmsTime();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkUser() {
      if (!this.phone) {
        this.$message.warning("请输入手机号");
        return false;
      }
      if (!this.code) {
        this.$message.warning("请输入验证码");
        return false;
      }
      this.$api
        .validateIosDownload({
          phone: this.phone,
          code: this.code,
        })
        .then((res) => {
          if (res.status == 1) {
            window.location.href = res.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setSmsTime() {
      this.smsTime = 60;
      this.smsTImer = setInterval(() => {
        if (this.smsTime === 0) {
          clearInterval(this.smsTImer);
        } else {
          this.smsTime -= 1;
        }
      }, 1000);
    },
    isMobile(value) {
      return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value);
    },
  },
};
</script>

<style scoped lang="scss">
.mb_download_ios_check {
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.ios_check {
  border: 1px solid #ebebeb;
  width: 400px;
  height: -webkit-max-content;
  height: max-content;
  background: #fff;
  padding: 37px 25px 57px;
  box-sizing: border-box;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.65);
  img {
    display: block;
    margin: 0 auto;
  }
}
.ios_check_head {
  font-size: 16px;
  font-weight: 700;
  margin: 50px 0 28px;
}
.input_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-input {
    width: 220px;
  }
}
.form_item > div {
  margin-bottom: 25px;
}
.full_btn {
  button {
    width: 100%;
  }
}
.isPhone {
  .ios_check {
    width: 100%;
    height: 100vh;
    padding: 10vh 10vw 0;
  }
  img {
    width: 20vw;
  }
  .ios_check_head {
    font-size: 55px;
    font-weight: 600;
    margin: 5vh 0 5vh;
  }
  .form_item > div {
    margin-bottom: 4vh;
  }
  .el-input {
    width: 50vw;
    font-size: 33px;
    ::v-deep .el-input__inner {
      padding-left: 7vw;
      height: 6vh;
    }
    ::v-deep .el-input__icon {
      width: 7vw;
      line-height: 6vh;
    }
  }
  .el-button {
    font-size: 33px;
    padding: 1.8vh 3vw;
  }
  .reacquire {
    padding: 2vh 3vw;
  }
  .full_input {
    .el-input {
      width: 100%;
    }
  }
}
.reacquire {
  padding: 12px;
}
</style>
